export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/admin": [4],
		"/admin/satisfactory-versions": [8],
		"/admin/tag-manager": [5],
		"/admin/unapproved-mods": [6],
		"/admin/unapproved-versions": [7],
		"/api-docs": [12],
		"/community": [26],
		"/content-policy": [29],
		"/guides": [10],
		"/guide/[guideId]": [14],
		"/guide/[guideId]/edit": [15],
		"/help": [18],
		"/mods": [28],
		"/mod/[modId]": [20],
		"/mod/[modId]/edit": [25],
		"/mod/[modId]/new-version": [24],
		"/mod/[modId]/version/[versionId]": [21],
		"/mod/[modId]/version/[versionId]/edit": [22],
		"/mod/[modId]/version/[versionId]/view": [23],
		"/new-guide": [27],
		"/new-mod": [19],
		"/privacy-policy": [3],
		"/settings": [9],
		"/sml-versions": [~11],
		"/tools": [13],
		"/tos": [16],
		"/user/[userId]": [17]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';